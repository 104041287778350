//
// features.scss
//

.features-count {
  h5 {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    background-color: rgba($primary,0.1);
    border-radius: 50%;
    line-height: 40px;
  }
}

.features-icon {
  i {
    font-size: 40px;
  }
}
