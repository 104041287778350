//
// clients.scss
//

.client-item{
  padding: 0px 80px;
  @media (max-width: 1024px) { 
    padding: 80px;
 }
 @media (max-width: 768px) { 
  padding: 0px;
}
}
.testi-box{
  border: 1px solid $gray-200;
  padding: 30px 40px;
  position: relative;
  margin-left: -100px;
  z-index: 1;
  background: $white;
  top: 70px;
  @media (max-width: 1000px) { 
      margin-left: 50px;
      top: -100px;
   }
   @media (max-width: 767px) { 
    margin-left: 0px;
    top: -100px;
 }
}

.testi-img{
  img{
      border-radius: 15px;
  }
}

.testi-title{
  font-size: 18px;
  line-height: 1.6;
}

.testi-icon{
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 0;
  @media (max-width: 1000px) { 
    right: auto;
    left: 30;
 }
  i{
      line-height: 50px;
      font-size: 30px;
  }
}



.tns-outer {
  position: relative;
  .tns-controls {
      position: absolute;
  top: 20px;
  right: 0px;
  z-index: 1;
      button {
          margin: 0 0 0px 5px;
          border-radius: 50%;
          border: none;
          background-color: rgba($primary, 0.2) !important;
          color: $primary;
          transition: all 0.3s;            
          font-size: 18px;
          &:focus, &:hover, &:active {
              outline: none;
              background-color: $primary !important;
              color: $white;
          }
          &.active {
              button {
                  background-color: darken($primary, 2%) !important;
              }
          }
      }
      &:focus {
          outline: none !important;
      }
  }
  .tns-nav {
      text-align: center;
      margin-top: 16px;
      button {
          width: 10px !important;
          height: 10px !important;
          border: none;
          background-color: rgba($primary, 0.2) !important;
          padding: 0px;
          margin: 0px 6px ;
          border-radius: 50%;
          &:focus, &:hover, &:active, &.active {
              outline: none;
              background-color: $primary !important;
          }
          &.tns-nav-active {
              background-color: $primary !important;
          }
      } 
  }    
}


// client img

.client-images {
  img {
    height: 40px;
    opacity: 1;
    transition: all 0.5s;
    &:hover {
      opacity: 0.5;
    }
  }
}
