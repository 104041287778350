//
// Counter.scss
//


.bg-counter{
  background-image: url(../images/Fondo/concepto-rpa-pantalla-tactil-mano-borrosa.jpg);
  background-position: center;
}



// CTA

.bg-cta{

  background-image: url(../images/Fondo/fondo-abstracto-diseno-poli.jpg);
  background-position: center;

}